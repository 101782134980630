import React from 'react'
import { Heading, Box } from '@chakra-ui/react';
import Layout from '../components/layout';

export default () => (
    <Layout>
        <Box>
            <Heading as="h1" size="xl">Uy, acá no hay nada... (Error 404)</Heading>
        </Box>
    </Layout>
)